import React, { useEffect, useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { browserName, browserVersion } from "react-device-detect";

const { Configuration, OpenAIApi } = require ("azure-openai"); 

//OpenAI Variables
//const {Configuration, OpenAIApi} = require('openai');
let messages = [];

//const { OpenAIClient, AzureKeyCredential } = require("@azure/openai");


let conversation = false;
let speechTimer;

// Add suggestion teacherType, teacherName, teacherDuty(optional), (optional) teacherHeader, (optional) teacherDescription
const MicConversation = ({playAudioProp, teacherName, isActive=false}) => {
    const [message, setMessage] = useState('');

    const {
        transcript,
        interimTranscript,
        finalTranscript,
        resetTranscript,
        listening,
    } = useSpeechRecognition();

    useEffect(() => {
        if (finalTranscript !== '' && isActive) {   

        }

        if(conversation === false){
            SpeechRecognition.stopListening();
            clearTimeout(speechTimer);
        }

        if(interimTranscript !== '' && isActive && conversation){            
            clearTimeout(speechTimer);
            speechTimer = setTimeout(onSpeechComplete, 2000);
        }

    }, [interimTranscript, finalTranscript]);

    
    function onSpeak(e) { 
        if(document.getElementById("modePracticeButton").disabled != true){
            document.getElementById("modePracticeButton").disabled = true;  
            document.getElementById("modePracticeButton").classList.add("disabled");
        }

        if(! conversation){
            initOpenAI();
            resetTranscript();
            conversation = true;
            micDisplay('true');           
            e.target.textContent = "End Conversation";
            listenContinuously();
            //document.getElementById('startOverButton').style.display = 'block';
        }
        else{
            conversation = false;
            micDisplay('false');
            e.target.textContent = "Start Conversation";
            resetTranscript();
            SpeechRecognition.stopListening();            
            
            document.getElementById("modePracticeButton").disabled = false;
            document.getElementById("modePracticeButton").classList.remove("disabled");   
            //document.getElementById('startOverButton').style.display = 'none';
        }
    }

    function micDisplay(bool) {
        switch (bool) {
          case 'true':
            document.getElementById('micMute').style.display = 'none';
            document.getElementById('micOpen').style.display = 'block';
            document.getElementById('micOpen').classList.remove('notransition');
            break;
          case 'false':
            document.getElementById('micOpen').style.display = 'block';
            document.getElementById('micMute').style.display = 'none';
            document.getElementById('micOpen').classList.add('notransition');
            break;
          case 'muted':
            document.getElementById('micOpen').style.display = 'none';
            document.getElementById('micMute').style.display = 'block';
            document.getElementById('micOpen').classList.add('notransition');
            break;
          default:
        }
      }

    function onSpeechComplete(){
        clearTimeout(speechTimer);
        SpeechRecognition.stopListening();
        micDisplay('muted');

        if(interimTranscript != ""){
            //openAiCall(interimTranscript);
            azureOpenAiCall(interimTranscript);
            //azureCognitiveCall(interimTranscript);
        } else{
            playAudioProp("Please say something so I understand.", {restartRecognition});
        }
    }

    function onStartOver(){
        initOpenAI();
        //startOverButton
        document.getElementById('startOverButton').style.display = 'block';
    }

    const listenContinuously = () => {
        SpeechRecognition.startListening({
          continuous: true,
          language: 'en-IN',
        });
    };


    const restartRecognition = () => {
        if(conversation){
            document.getElementById('action').style.display = 'none';
            document.getElementById('talkButton').style.display = 'block';

            resetTranscript();
            micDisplay('true');
            listenContinuously();
        }
    }

    //OPENAI CALL
    //------------------------------------------------------------------------------   
    function initOpenAI(){
        messages = [];
        messages.push({role: 'system', content: "Your name is "+ teacherName +". You enjoy engaging in natural conversation. Keep your answers brief, with a little humour and easy to understand. Enforce correction if the users grammar if incorrect. Try to make your answers under 70 words in length. You are listening to the user through speech to text, so if they say can you hear me you reply yes, loud and clear. Be concise."});
        //messages.push({role: 'system', content: "You are an English teacher called "+ teacherName +". Your job is to teach English to students that use English as a second language. The students English is not very good and they are using you to practice conversation skills. Keep your answers brief and simple so the students understand. Try to make your answers under 60 words in length."});
    }

    /*async function openAiCall(userInputText) {
        const configuration = new Configuration({
            apiKey: openAIKey,
        });
        const openai = new OpenAIApi(configuration);

        const userInput = userInputText;
        messages.push({role: 'user', content: userInput});

        try {
            const completion = await openai.createChatCompletion({
                model: 'gpt-3.5-turbo',
                messages,
            });

            const completionText = completion.data.choices[0].message.content;
            messages.push({role: 'assistant', content: completionText});

            playAudioProp(completionText, {restartRecognition});
        } catch (error) {
            playAudioProp("A I error. Please ask me again.", {restartRecognition});
            if (error.response) {
                console.log(error.response.status);
                console.log(error.response.data);
            } else {
                console.log(error.message);
            }
        }
    }*/
    //END OPENAI CALL----------------------------------------------------------------
    
    //AZURE OPENAI CALL--------------------------------------------------------------------
    async function azureOpenAiCall(userInputText) {

        document.getElementById('action').textContent = "Thinking...";
        document.getElementById('action').style.display = 'block';
        document.getElementById('talkButton').style.display = 'none';

        const configuration = new Configuration({
            apiKey: process.env.REACT_APP_AZURE_OPENAI_KEY,
            azure: {
                apiKey: process.env.REACT_APP_AZURE_OPENAI_KEY,
                endpoint: process.env.REACT_APP_AZURE_OPENAI_ENDPOINT,
                // deploymentName is optional, if you donot set it, you need to set it in the request parameter
                deploymentName: "elearnlk",
             }
        });
        
        const openai = new OpenAIApi(configuration);

        const userInput = userInputText;
        messages.push({role: 'user', content: userInput});

        try {
            const completion = await openai.createChatCompletion({
                model: 'gpt-4o',
                messages,
            });
            //model: 'gpt-4-32k',

            const completionText = completion.data.choices[0].message.content;
            messages.push({role: 'assistant', content: completionText});


            document.getElementById('action').textContent = "Speaking...";


            playAudioProp(completionText, {restartRecognition});
        } catch (error) {
            playAudioProp("A I error. Please ask me again.", {restartRecognition});
            if (error.response) {
                console.log(error.response.status);
                console.log(error.response.data);
            } else {
                console.log(error.message);
            }
        }
    }
    //END AZURE OPENAI CALL----------------------------------------------------------------
    
    return (
        <div id="chatbotUiScreen" className="screen">            
            <div id="uiPanel" className="panel">
                <p className="instructions pb-2">
                Press the button below to begin the conversation. 
                </p>
                <div id="conversationButtonCon" data-isspeechplaying="false">
                <svg id="micOpen" className="notransition" viewBox="0 0 30 30" width="30" height="30">
                    <path d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z"></path>
                </svg>
                <svg id="micMute" viewBox="0 0 30 30" width="30" height="30">
                    <path d="M19 11h-1.7c0 .74-.16 1.43-.43 2.05l1.23 1.23c.56-.98.9-2.09.9-3.28zm-4.02.17c0-.06.02-.11.02-.17V5c0-1.66-1.34-3-3-3S9 3.34 9 5v.18l5.98 5.99zM4.27 3 3 4.27l6.01 6.01V11c0 1.66 1.33 3 2.99 3 .22 0 .44-.03.65-.08l1.66 1.66c-.71.33-1.5.52-2.31.52-2.76 0-5.3-2.1-5.3-5.1H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c.91-.13 1.77-.45 2.54-.9L19.73 21 21 19.73 4.27 3z"></path>
                </svg>
                <button data-convobool="nonActive" id="startOverButton" onClick={onStartOver} style={{display:'none'}}>Start Over</button>
                <div className='pt-2 text-red-500' id="action" style={{display:'none'}}>Thinking...</div>
                <button data-convobool="nonActive" id="talkButton" onClick={onSpeak}>Start Conversation</button>
                </div>  
            </div>
        </div>
    );
}
export default MicConversation;