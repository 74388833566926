/*
© 2024 Glasir Games Pty Ltd trading as ®Soulbotix. All Rights Reserved 
*/

import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Authenticator, withAuthenticator, Button, Heading, View, Flex } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './assets/chatGPTDemo.css';
import './output.css';
import AvatarSelection from './AvatarSelection';
import { Auth, Hub } from 'aws-amplify';
import MainScene from './MainScene';

import Cookies from 'js-cookie';

var AWS = require("aws-sdk");

async function signOutFunc() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

function TeacherSelection() {
  const [showTeacher, setShowTeacher] = useState(true);
  const [selectedTeacherModel, setSelectedTeacherModel] = useState('');
  const [selectedTeacherName, setSelectedTeacherName] = useState('');

  function teacherSelected(teacherModel, teacherName){
    setSelectedTeacherModel(teacherModel);
    setSelectedTeacherName(teacherName);

    setShowTeacher(false);
  }

  return (
    <div>
      {showTeacher ?
        <AvatarSelection teacherSelected={teacherSelected}/>      
      :
        <MainScene tts='AWS' signOut={signOutFunc} selectedTeacher={selectedTeacherModel} selectedTeacherName={selectedTeacherName} isTrial={false}/>
      }

      {/* AWS or AZURE */}
    </div>
  );
}

export default function App() {
  const [isSigned, setIsSigned] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const [isAuth, setIsAuth] = useState(false);

  const listener = (data) => {
    switch (data?.payload?.event) {
      case 'configured':
        console.log('the Auth module is configured');
        break;
      case 'signIn':
        setIsAuth(true);
        console.log('user signed in');
        break;
      case 'signOut':
        setIsAuth(false);
        console.log('user signed in');
        break;
      case 'autoSignIn':
        setIsAuth(true);
        console.log('auto sign in successful');
        break;
    }
  };

  useEffect(() => {
    const fetchGet = async () => {
      const queryParameters = new URLSearchParams(window.location.search);
      const id = queryParameters.get("id");
      const token = queryParameters.get("token");
      if(id == null || token == null){
        setIsSigned(false);
      } else {
        try {
          const response = await fetch(
            'https://learn-soulbotix-api.azurewebsites.net/users/verify/' + id + '/' + token, {headers: {Accept: 'application/json'}}
          );
          const data = await response.json();
          //console.log(data.authenticated);
          setIsSigned(data.authenticated);
        }catch (error) {
          console.log(error);
          setIsSigned(false);
        }
      }      
    };
    fetchGet();

    const bool = async () => {
      try {
        await Auth.currentAuthenticatedUser({
          bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        console.log("success");
        setIsAuth(true);
      } catch {
        console.log("fail");
        setIsAuth(false);
      }
    };
    bool();
    
  }, []);
  
  Hub.listen('auth', listener);

  return (
    <div>
      {isSigned ?
        <TeacherSelection/>
      :
        <div>
          {isTrial ?
            <MainScene tts='AWS' signOut={signOutFunc} selectedTeacher={"Cristine"} selectedTeacherName={"Sarah"} isTrial={true}/>
          :
          
            
            <div
              
              
            >
              {({ signOut, user }) => (
                <TeacherSelection/>
              )}
            </div>
            
          }
          {!isAuth &&
            <View
              as="div"
              width="100%"
            >
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                wrap="nowrap"
                gap="1rem"
              >
                <Button
                  disabled={Cookies.get('trial') == "complete"}
                  marginTop="2rem"
                  variation="primary"
                  size="large"
                  loadingText=""
                  onClick={() =>  setIsTrial(true)}
                >
                  {Cookies.get('trial') == "complete"? "Trial expired":"Get a 5 min Free Trial. - යෙදුම නොමිලේ විනාඩි 5ක් උත්සාහ කරන්න"}
                </Button>
                <Button
                  marginTop="2rem"
                  variation="primary"
                  size="large"
                  loadingText=""
                  onClick={() =>  window.open("https://aiteacher.elearning.lk/courses/ai-english-teacher")}
                >
                  Purchase Monthly Subscription - මාසික ග්‍රාහකයකු වන්න
                </Button>
              </Flex>
            </View>
            
          }
          
        </div>
      }
    </div>
  );
}