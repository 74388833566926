import React, { useEffect, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

//OpenAI Variables
//const {Configuration, OpenAIApi} = require('openai');
const { Configuration, OpenAIApi } = require ("azure-openai"); 
let messages = [];

let initOpenAIBool = false;
let initTimerBool = false;
let timerInit = false;
let timer;
let isSpeaking = false;
let processing = false; 

let txt = "";
let finalTxt = "";

const MicPractice = ({playAudioProp, teacherName, isActive=false}) => {

    if(!timerInit && isActive){
        timerInit = true;
        timer = setTimeout(()=>{
          initTimerBool = true;
          clearTimeout(timer);
        }, 1000);
    }

    document.getElementById("modeConversationButton")?.addEventListener("click", terminateSpeech);

    function terminateSpeech(){
        if(SpeechRecognition != null){
            SpeechRecognition.stopListening();
            resetTranscript();
            finalTxt = "";
            txt = "";
            isSpeaking = false;
            processing = false;
            clearTimeout(timer);
            initTimerBool = false;
            timerInit = false;
        }
    }
    
    if(!initOpenAIBool){
        SpeechRecognition.stopListening();
        initOpenAIBool = true;
        initOpenAI();    
    }

    const [startNum, setStartNum] = useState(1);
    const [message, setMessage] = useState('');
    
    const {
        transcript,
        interimTranscript,
        finalTranscript,
        resetTranscript,
        listening,
    } = useSpeechRecognition();


    useEffect(() => {
        if(txt == ''){
            document.getElementById('clearButton').style.display = 'none';
        }

        if(interimTranscript == '' && initTimerBool){
            finalTxt = finalTxt + " " + txt;
            document.getElementById('humanText').textContent = finalTxt;  
        }

        if(interimTranscript !== '' && initTimerBool){
          console.log("Interim Result");
          document.getElementById('clearButton').style.display = 'block';
          isSpeaking = true;
          txt = interimTranscript;          
          document.getElementById('humanText').textContent = finalTxt + " " + txt;
        }
    }, [interimTranscript, finalTranscript]);

    const listenContinuously = () => {
        SpeechRecognition.startListening({
          continuous: true,
          language: 'en-IN',
        });
    };

    function onStartProgress(){
        if(!isSpeaking && ! processing){
          isSpeaking = false;
          processing = true;

          document.getElementById('talkButton').style.display = 'none';
          document.getElementById('startButton').style.display = 'none';
          document.getElementById('micOpen').style.display = 'none';
          document.getElementById('clearButton').style.display = 'none';          
  
          if(startNum == 1){
            azureOpenAiCall("How are you "+ teacherName +", I would like to learn spoken English from you.");
          } else if(startNum == 2){
            azureOpenAiCall("Can you suggest me 5 simple day to day topics we can discuss about?");
          } else if(startNum == 3){          
            azureOpenAiCall("I’m not very good at English, but I will try to speak in English. If you see any errors or improvements please correct me along with grammatical reasoning as needed.");
          } else {
            
            document.getElementById('talkButton').style.display = 'block';
          }
          setStartNum(startNum+1);
        }
    }
     
    function onSpeak(e) {        
        if(! isSpeaking && ! processing){
            isListening('true');
            isSpeaking = true;            
            e.target.textContent = "Send Reply";
            listenContinuously();


            document.getElementById("modeConversationButton").disabled = true;   
            document.getElementById("modeConversationButton").classList.add("disabled"); 


        }
        else if (isSpeaking && ! processing){
            isListening('false');
            isSpeaking = false;            
            e.target.textContent = "Start Talking";
            SpeechRecognition.stopListening();
            processSpeech();
        }
        else if (! isSpeaking && processing){
            isListening('false');
            isSpeaking = false;            
            e.target.textContent = "Start Talking";
            resetTranscript();
            finalTxt = "";
            txt = "";
            document.getElementById('humanText').textContent = ""; 
        }
    }
  
    function isListening(bool) {
        switch (bool) {
        case 'true':
            document.getElementById('micMute').style.display = 'none';
            document.getElementById('micOpen').style.display = 'block';
            document.getElementById('micOpen').classList.remove('notransition');
            break;
        case 'false':
            document.getElementById('micOpen').style.display = 'block';
            document.getElementById('micMute').style.display = 'none';
            document.getElementById('micOpen').classList.add('notransition');
            break;
        case 'muted':
            document.getElementById('micOpen').style.display = 'none';
            document.getElementById('micMute').style.display = 'block';
            document.getElementById('micOpen').classList.add('notransition');
            break;
        default:
        }
    }
  
    function onClearHumanText(){
        resetTranscript();
        finalTxt = "";
        txt = "";
        document.getElementById('humanText').textContent = "";
        document.getElementById('clearButton').style.display = 'none';
    }

    function onStartOver(){
        setStartNum(1);
        initOpenAI();
    }

    function processSpeech(){
        console.log("Final: " + finalTxt);

        if(finalTxt == '' && txt!= ''){
            finalTxt = txt;
        }


        if(finalTxt != ""){
            azureOpenAiCall(finalTxt);
        } else{
            playAudioProp("Please say something so I understand.", {restartRecognition});
        }
        
        isSpeaking = false;
        processing = true;
        document.getElementById('action').style.display = 'block';
        document.getElementById('talkButton').style.display = 'none';

        document.getElementById('micOpen').style.display = 'none';
        document.getElementById('clearButton').style.display = 'none';
    }

    const restartRecognition = () => {
        resetTranscript();
        finalTxt = "";
        txt = "";
        isSpeaking = false;
        processing = false;

        document.getElementById('micOpen').style.display = 'block';
        document.getElementById('humanText').textContent = "";

        document.getElementById('talkButton').style.display = 'block';
        document.getElementById('action').style.display = 'none';
        document.getElementById('action').textContent = "Thinking...";
        document.getElementById('startButton').style.display = 'block';

        document.getElementById("modeConversationButton").disabled = false;   
        document.getElementById("modeConversationButton").classList.remove("disabled");
    }
  
    //OPENAI CALL
    //------------------------------------------------------------------------------   
    function initOpenAI(){
    messages = [];
    messages.push({role: 'system', content: "You are an English teacher called "+ teacherName +". Your job is to teach English to students that use English as a second language. The students English is not very good and they are using you to practice conversation skills. Keep your answers brief and simple so the students understand. Try to make your answers under 60 words in length. If the students make any mistakes in grammar or in pronunciation, correct them. Always enforce correction. Also if you can reword their response into what a native English speaker would say, teach them. You are listening to the user through speech to text, so if they say can you hear me you reply yes, loud and clear. Be concise."});
    }
  
    async function azureOpenAiCall(userInputText) {
        const configuration = new Configuration({
            apiKey: process.env.REACT_APP_AZURE_OPENAI_KEY,
            azure: {
                apiKey: process.env.REACT_APP_AZURE_OPENAI_KEY,
                endpoint: process.env.REACT_APP_AZURE_OPENAI_ENDPOINT,
                // deploymentName is optional, if you donot set it, you need to set it in the request parameter
                deploymentName: "elearnlk",
             }
        });
        
        const openai = new OpenAIApi(configuration);

        const userInput = userInputText;
        messages.push({role: 'user', content: userInput});

        try {
            const completion = await openai.createChatCompletion({
                model: 'gpt-4o',
                messages,
            });
            //model: 'gpt-4-32k',

            const completionText = completion.data.choices[0].message.content;
            messages.push({role: 'assistant', content: completionText});

            document.getElementById('action').textContent = "Speaking...";
            playAudioProp(completionText, {restartRecognition});
        } catch (error) {
            document.getElementById('action').textContent = "Speaking...";
            playAudioProp("A I error. Please ask me again.", {restartRecognition});
            if (error.response) {
                console.log(error.response.status);
                console.log(error.response.data);
            } else {
                console.log(error.message);
            }
        }
    }
    //END OPENAI CALL----------------------------------------------------------------


    return(
        <div id="chatbotUiScreen" className="screen">           
            <div id="uiPanel" className="panel">

            <div id="startContainer">
                <div>Start Here</div>                  
                {startNum == 1?                  
                    <button id="startButton" onClick={onStartProgress}>ඉතින් කොහොමද {teacherName}, මම කැමතියි ඔබෙන් ඉංග්‍රීසි කථනය ඉගෙන ගන්න</button>
                : startNum == 2?
                    <button id="startButton" onClick={onStartProgress}>ඔබ කැමති මාතෘකාවක් කියන්න අපි දෙන්නට කතා කරන්න පුළුවන්</button>
                : startNum == 3?                                        
                    <button id="startButton" onClick={onStartProgress}>මම මෙතැන් සිට වරද්ද වරද්දා හෝ ඉංග්‍රීසි කතා කරනවා. වැරදුනොත් මාව නිවැරදි කරන්න</button>
                :
                    <>
                        <div className='p-2'>මෙතැන් පටන් ඔබට සිංහල භාෂාවෙන් උපදෙස් ලබාදිය නොහැකිය. ඉංග්‍රීසි භාෂාවෙන් පමණක් උත්සාහ කරන්න</div>
                        <button data-convobool="nonActive" id="startOverButton" onClick={onStartOver} style={{display:'block'}}>නැවත මුල සිට පටන්ගමු</button>
                    </>
                }
            </div>

            <p className="instructions pt-2 pb-2">
                Press the button below to begin the conversation. 
            </p>
            <div id="conversationButtonCon" data-isspeechplaying="false">
                <svg id="micOpen" className="notransition" viewBox="0 0 30 30" width="30" height="30">
                    <path d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z"></path>
                </svg>
                <svg id="micMute" viewBox="0 0 30 30" width="30" height="30">
                    <path d="M19 11h-1.7c0 .74-.16 1.43-.43 2.05l1.23 1.23c.56-.98.9-2.09.9-3.28zm-4.02.17c0-.06.02-.11.02-.17V5c0-1.66-1.34-3-3-3S9 3.34 9 5v.18l5.98 5.99zM4.27 3 3 4.27l6.01 6.01V11c0 1.66 1.33 3 2.99 3 .22 0 .44-.03.65-.08l1.66 1.66c-.71.33-1.5.52-2.31.52-2.76 0-5.3-2.1-5.3-5.1H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c.91-.13 1.77-.45 2.54-.9L19.73 21 21 19.73 4.27 3z"></path>
                </svg>
                <button data-convobool="nonActive" id="talkButton" onClick={onSpeak}>Start Talking</button>
            </div>
            <div className='pt-2 text-red-500' id="action" style={{display:'none'}}>Thinking...</div>
            <div className='text-blue-600 pt-2' id="humanText"></div>
            <button data-convobool="nonActive" id="clearButton" onClick={onClearHumanText}>Clear</button>              
            </div>
        </div>
    );
}
export default MicPractice;