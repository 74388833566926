
import React, { Component } from "react";

class TranslateComponent extends Component {
    constructor(props){
        super(props);
    
        this.state = {
          value: '',
          translated: ''
        }
        this.translate=this.translate.bind(this);
      }

    componentDidMount() {

    }

    translate = ()=> {
        let fromLang = 'si'; // translate from Sri Lankan
        let toLang = 'en'; 
        let text = this.state.value;

        let url = `https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY}`;
        url += '&q=' + encodeURI(text);
        url += `&source=${fromLang}`;
        url += `&target=${toLang}`;

        fetch(url, { 
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
        })
        .then(res => res.json())
        .then((response) => {
            this.setState({translated: response.data.translations[0].translatedText})
        })
        .catch(error => {
            console.log("There was an error with the translation request: ", error);
        });
    }

    speak = () => {
        if(this.state.translated != '')
            this.props.playAudio(this.state.translated, {});
    }

    clear = () => {
        this.setState({value: ''})
        this.setState({translated: ''})
    }

    render() {

        return (
            <div id="translateContainer">
                <div id="translateHeader">ඉංග්රීසි භාෂාවට පරිවර්තනය කරන්න</div><br/>
                <input 
                    className="text-black p-2"
                    size="36"
                    value={this.state.value}
                    onChange={(e)=>this.setState({value: e.target.value})}
                    type="text"
                    placeholder="සිංහල unicode මගින් යමක් type කරන්න"/><br/><br/>
                <button className="p-1" onClick={this.translate} id="talkButton">පරිවර්තනය කරන්න</button>

                {this.state.translated != ''?
                    <>
                        <div id="translateList">
                            <div id="translateItem">{this.state.translated}</div>
                        </div>
                        <div id="spacer"></div>
                        <button onClick={this.speak} id="talkButton">උච්චාරණය කරන්න</button>
                        <div id="spacer"></div>
                        <button onClick={this.clear} id="talkButton">Reset</button>
                    </>
                    :
                    <></>
                }
            </div>
        );
    }
}

export default TranslateComponent;