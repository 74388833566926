/*
© 2024 Glasir Games Pty Ltd trading as ®Soulbotix. All Rights Reserved 
*/

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

import cristine from "./assets/characters/small/christine.png";
import fiona from "./assets/characters/small/fiona.png";
//import grace from "./assets/characters/small/grace.png";
//import maya from "./assets/characters/small/maya.png";
//import jay from "./assets/characters/small/jay.png";
import luke from "./assets/characters/small/luke.png";
//import preston from "./assets/characters/small/preston.png";
import wes from "./assets/characters/small/wes.png";

let txtSelection = "Cristine";
let txtName = 'Sarah';

const slideImages = [
    {
        url: cristine,
        caption: 'Cristine',
        name: 'Sarah'
    },
    {
        url: fiona,
        caption: 'Fiona',
        name:'Sanduni'
    },
    /*{
        url: grace,
        caption: 'Grace',
        name: 'Nazra'
    },
    {
        url: maya,
        caption: 'Maya',
        name: 'Radha'
    },
    {
        url: jay,
        caption: 'Jay',
        name: 'Tharindu'
    },*/
    {
        url: luke,
        caption: 'Luke',
        name: 'Nishanthan'
    },
    /*{
        url: preston,
        caption: 'Preston',
        name:'Wasim'
    },*/
    {
        url: wes,
        caption: 'Wes',
        name:'Chris'
    }
];

const onSliderChange = (indexOld, indexNew) => {
    txtSelection = slideImages[indexNew].caption;
    txtName = slideImages[indexNew].name;
}

export default function SelectAvatar({teacherSelected, teacherName}) {
    return (
        <div className="bg-black">
            <div className='flex align-middle text-center w-full justify-center h-12 text-white font-bold text-lg'>Select Teacher</div>
            <Slide autoplay={false} onStartChange={onSliderChange}>
            {slideImages.map((slideImage, index)=> (
                <div key={index}>
                <div className='flex items-center justify-center h-[500px] bg-contain bg-no-repeat bg-center' style={{ backgroundImage:`url(${slideImage.url})`}}>                    
                    <span className='p-1 mt-[400px] bg-[#efefef] text-black'>{slideImage.name}</span>
                </div>
                </div>
            ))} 
            </Slide>
            <button className='h-12 bg-gray-800' onClick={()=>{teacherSelected(txtSelection, txtName)}}>Press to Continue</button>
        </div>
    );
};