/*
© 2024 Glasir Games Pty Ltd trading as ®Soulbotix. All Rights Reserved 
*/

import React, { useEffect, useState, CSSProperties  } from 'react';
import SceneComponent from "./components/SceneComponent";

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import TimerIcon from '@mui/icons-material/Timer';

import Cookies from 'js-cookie';

import {HostObject as AzureHostObject} from '@amazon-sumerian-hosts/babylonazure';
import {HostObject as AwsHostObject} from '@amazon-sumerian-hosts/babylonaws';
import {Scene} from '@babylonjs/core/scene';
import DemoUtils from './demo-utils';
import './assets/chatGPTDemo.css';

import MicConversation from './MicConversation';
import MicPractice from './MicPractice';
import Translate from './TranslateComponent';

var AWS = require("aws-sdk");
let host;
let teacher = '';
let trialActive = false;
let ttsEngine;

const onSceneReady = async (scene) => {
  scene.getEngine().disableUniformBuffers = true;

  scene.useRightHandedSystem = true;
  const {shadowGenerator} = DemoUtils.setupSceneEnvironment(scene);

  let voice = 'Joanna';

  if(ttsEngine == "AZURE"){
    switch(teacher){
      case "Cristine":
        voice = "en-US-JennyNeural";
        break;
      case "Fiona":
        voice = "en-US-JaneNeural";
        break;
      case "Luke":
        voice = "en-US-ChristopherNeural";
        break;
      case "Wes":
        voice = "en-US-BrandonNeural";
        break;
    }
  } else if(ttsEngine == "AWS"){
    //Female Voices
    if(teacher == 'Cristine'){
      voice = 'Joanna';
    }
    if(teacher == 'Fiona'){
      voice = 'Kendra';
    }
    if(teacher == 'Grace'){
      voice = 'Emma';
    }
    if(teacher == 'Maya'){
      voice = 'Kajal';
    }

    //Male Voices
    if(teacher == 'Jay'){
      voice = 'Matthew';
    }
    if(teacher == 'Luke'){
      voice = 'Stephen';
    }
    if(teacher == 'Preston'){
      voice = 'Brian';
    }
    if(teacher == 'Wes'){
      voice = 'Joey';
    }
  }

  AWS.config.region = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID.split(':')[0];
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  });

  const characterId = teacher;

  const pollyConfig = {pollyVoice: voice, pollyEngine: 'neural'};
  
  if(ttsEngine == "AZURE"){
    const azureCred = {apiKey: process.env.REACT_APP_AZURE_SPEECH_API_KEY, region: process.env.REACT_APP_AZURE_SPEECH_REGION};
    const characterConfig = AzureHostObject.getCharacterConfig(
      '/character-assets',
      characterId
    );
    try {
      host = await AzureHostObject.createHost(scene, characterConfig, pollyConfig, azureCred);
    } catch (error) {
      console.log(error);
    }
  } else if(ttsEngine == "AWS"){
    const characterConfig = AwsHostObject.getCharacterConfig(
      '/character-assets',
      characterId
    );
    try {
      host = await AwsHostObject.createHost(scene, characterConfig, pollyConfig);
    } catch (error) {
      console.log(error);
    }
  }

  // Tell the host to always look at the camera.
  host.PointOfInterestFeature.setTarget(scene.activeCamera);


  // Enable shadows.
  scene.meshes.forEach(mesh => {
    shadowGenerator.addShadowCaster(mesh);
  });

  //INIT SCREEN*******************
  initUi();
  //INIT SCREEN*******************

  function initUi() {
    setElementVisibility('loadingScreen', false);
    // Set up interactions for UI buttons.
    showUiScreen('startScreen');
    document.getElementById('mainScreen').classList.remove('loading');
    document.getElementById('startButton').onclick = () => startMainExperience();
  }
  
  //
  //  Triggered when the user clicks the initial "start" button.
  //
  function startMainExperience() {
    if(trialActive){
      //startTrialTimer();
      document.getElementById('renderCanvas').classList.add('trialMode');
      document.getElementById('uiScreens').classList.add('trialModePadding');
      setElementVisibility('trialTimer', true);
    }
    showUiScreen('chatbotUiScreen');
    setElementVisibility('selectMode', true);
  }
  
  // ===== Utility functions =====    
  //
  // Makes the specified UI screen visible and hides all other UI screens.
  // @param {string} id HTMLElement id of the screen to display.
  //
  function showUiScreen(id) {     
    document.querySelectorAll('#uiScreens .screen').forEach(element => {
      const isTargetScreen = element.id === id;
      setElementVisibility(element.id, isTargetScreen);
    });
  }
  
  //
    // Shows or hides an HTML element.
    // @param {string} id HTMLElement id
    // @param {boolean} visible `true` shows the element. `false` hides it.
    //
  function setElementVisibility(id, visible) {
    const element = document.getElementById(id);
    if (visible) {
      element.classList.remove('hide');
    } else {
      element.classList.add('hide');
    }
  }
}

// Will run on every frame render.
const onRender = (scene) => {
    
};



export default function MainScene({ signOut, user, selectedTeacher, selectedTeacherName, isTrial=false, tts="AWS" }) {
  const [showConversation, setShowConversation] = useState(true);
  const [cc, setCC] = useState('');


  function showWave(){
    //Gestures: aggressive, big, defense, generic_a, generic_b, generic_c, heart, in, many, movement, one, self, wave, you
    const gestureOptions = {
      holdTime: 1.5, // how long the gesture should last
      minimumInterval: 0, // how soon another gesture can be triggered
    };
    host.GestureFeature.playGesture('Gesture', 'wave', gestureOptions);
    playAudio("Hello and welcome. Great to see you again.", nullRestartRecognition);
  }
  function nullRestartRecognition(){

  }


  //Loading variables
  let [progressVal, setProgressVal] = useState(0);
  let [loadingText, setLoadingText] = useState("Loading...");
  //////////////////
  let [trialTimerText, setTrialTimerText] = useState("5:00");

  function startTrialTimer() {
    var time = 300000;

    let timerInterval = setInterval(()=>{
      time = time - 1000;

      let date = new Date(time);
      setTrialTimerText(date.toISOString().substring(14, 19));

      if(time < 1000){
        clearInterval(timerInterval);
        window.location.replace("https://aiteacher.elearning.lk/courses/ai-english-teacher")
      }
    }, 1000);
    Cookies.set('trial', 'complete', { expires: 365 });
  } 

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgressVal((oldProgress) => {
        if (oldProgress >= 95) {
          setLoadingText("Almost done...");
        }
        if (oldProgress >= 100) {
          setLoadingText("Almost done...");
          return 100;
        }
        return oldProgress + 1;
      });
    }, 300000/100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const onConversationMode = (e) =>{
    setShowConversation(true);
    document.getElementById('modeConversationButton').style.color = "yellow";
    document.getElementById('modePracticeButton').style.color = "white";
  } 

  const onPracticeMode = (e) =>{
    setShowConversation(false);
    document.getElementById('modeConversationButton').style.color = "white";
    document.getElementById('modePracticeButton').style.color = "yellow";
  }

  var audioTracker = 0;
  var str;
  var result;
  const playAudio = async(completionText, {restartRecognition}) => {          
    if(audioTracker == 0){
      str = completionText;
      result = str.match(/[^\.\?!]+[.!?]+[\])'"`’”]*|.+/g);
    }
    setCC(result[audioTracker]);

    await host.TextToSpeechFeature.play(result[audioTracker]);
    
    audioTracker++;
    if(result.length == audioTracker)
    {
      audioTracker = 0;
      str = null;
      result = null;
      setCC('');                
      if(restartRecognition != null)
        restartRecognition();
    }
    else{
      playAudio('', {restartRecognition});
    }
  }

  teacher = selectedTeacher;
  trialActive = isTrial;
  ttsEngine = tts;




  /*
  const audioInputSelect = document.querySelector('select#audioSource');
  const selectors = [audioInputSelect];
  
  function gotDevices(deviceInfos) {
    // Handles being called several times to update labels. Preserve values.
    const values = selectors.map(select => select.value);
    selectors.forEach(select => {
      while (select.firstChild) {
        select.removeChild(select.firstChild);
      }
    });
    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      const option = document.createElement('option');
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === 'audioinput') {
        option.text = deviceInfo.label || `microphone ${audioInputSelect.length + 1}`;
        audioInputSelect.appendChild(option);
      }
    }
    selectors.forEach((select, selectorIndex) => {
      if (Array.prototype.slice.call(select.childNodes).some(n => n.value === values[selectorIndex])) {
        select.value = values[selectorIndex];
      }
    });
  }
  navigator.mediaDevices.enumerateDevices()
  .then(gotDevices);
*/







  return (
    <div>       
      <div id="mainScreen" className="screen loading">       
        <SceneComponent antialias onSceneReady={onSceneReady} onRender={onRender} id="renderCanvas" />
        <div id="uiScreens">        
          <div id="startScreen" className="screen modal hide">
            <div id="startDialog" className="dialog">
                <h2 className='p-2'>Talk to {selectedTeacherName} the English teacher{isTrial?". This is a limited 5 minute trial.":""}</h2>
                
                {/*
                <div className='p-2'>Microphone: <select id="audioSource"></select> </div>
                <div className='p-2'>Speakers:</div>
                */}
                
                <button className='p-2' onClick={isTrial?startTrialTimer:()=>{showWave()}} id="startButton">{isTrial?"Start Trial":"Start"}</button>
                {!isTrial && 
                  <button className='p-2' onClick={signOut}>Logout</button>
                }
                <p className="developerNote">
                <span className="label"></span>
                </p>
            </div>
          </div>

          <div id="trialTimer" className="hide">
            <Chip label="TRIAL MODE" color="error"/>
            <Chip sx={{marginLeft:"10px"}} label={trialTimerText} color="error" icon={<TimerIcon />}/>
          </div>
          

          <div>
            <div className='' id='betaLabel'>Beta version 1.0</div>
            <div id="selectMode" className="hide">              
              <button className='p-2 bg-black bg-opacity-50' data-convobool="nonActive" id="modeConversationButton" onClick={onConversationMode}>Conversation Mode</button>
              <button className='p-2 bg-black bg-opacity-50' data-convobool="nonActive" id="modePracticeButton" onClick={onPracticeMode}>Practice Mode</button>            
            </div>
          </div>

          <div id="controlWrapper">
            {showConversation ?                      
              <MicConversation playAudioProp={playAudio} teacherName={selectedTeacherName} isActive={showConversation}/>
              :
              <>
                <MicPractice playAudioProp={playAudio} teacherName={selectedTeacherName} isActive={!showConversation?true:false}/>
                <Translate playAudio={playAudio} />      
              </>          
            }
            
          </div>
          { cc != ''?
            <div id="ccPanel" className="darkPanel">
              <div>{cc}</div>
            </div>
          :
            <></>
          }

        </div>
      </div>
        
      <div id="loadingScreen" className="screen">
        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: "center", alignItems: "center", paddingLeft: "2em", paddingRight: "2em" }}>
          <Box sx={{ width: '100%' }}>
            <LinearProgress sx={{ height: 12 }} variant="determinate" value={progressVal}/>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <p>&nbsp;{loadingText}</p>
          </Box>
        </Box>        
      </div>
    </div>
  );
};


const styles = {
  container: { width: "100%", margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
  todo: {  marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  todoName: { fontSize: 20, fontWeight: 'bold' },
  todoDescription: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}